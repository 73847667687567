<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    table_data: [Array, Object],
  },
  data: () => ({
    chart_data_S: [],
    chart_data_V: [],
    chart_data_P: [],
    chart_labels: [],
    options: {
      scales: {
          yAxes: [
            {
              ticks: {
                suggestedMax: 400,
              },
            },
          ],
        },
      legend:{
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: 10,
        width: 100,
      },
    },
  }),
  created() {
    this.table_data.forEach((el) => {
      this.chart_labels.push(el.week);
      this.chart_data_S.push(el.s);
      this.chart_data_P.push(el.p);
      if (el.v) {
        this.chart_data_V.push(el.v);
      }
    });

    if (this.chart_data_P) {
      this.ChartData = {
        labels: this.chart_labels,
        datasets: [
          {
            label: "S",
            fill: false,
            backgroundColor: "#8ecf1f",
            data: this.chart_data_S,
          },
          {
            label: "V",
            fill: false,
            backgroundColor: "#d15019",
            data: this.chart_data_V,
          },
          {
            label: "P",
            fill: false,
            backgroundColor: "#2c81d1",
            data: this.chart_data_P,
          },
        ],
      };
    } else {
      this.ChartData = {
        labels: this.chart_labels,
        datasets: [
          {
            label: "S",
            backgroundColor: "#8ecf1f",
            data: this.chart_data_S,
          },
          {
            label: "V",
            backgroundColor: "#d15019",
            data: this.chart_data_V,
          },
        ],
      };
    }
  },
  mounted() {
    this.renderChart(this.ChartData, this.options);
  },
};
</script>
