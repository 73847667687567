<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <h2 class="text-center">Voorraad historie jaar</h2>
    <hr />
    <Loading v-if="loading" />
    <div v-else class="graph-layout">
      <div class="graph graph--border flex-grow">
        <h4 class="text-center">Aantallen</h4>
        <Loading v-if="!aantal_data" />
        <LineChart v-if="aantal_data.length" :table_data="aantal_data" />
        <div class="flex _row center">
          <span class="dot dot--s m1"></span>S<span class="dot dot--v m1"></span
          >V<span class="dot dot--p m1"></span>P
        </div>
      </div>
      <div class="graph graph--border flex-grow">
        <h4 class="text-center">Waarde x 1000</h4>
        <LineChart v-if="waarde_data.length" :table_data="waarde_data" />
        <div class="flex _row center">
          <span class="dot dot--s m1"></span>S<span class="dot dot--v m1"></span
          >V<span class="dot dot--p m1"></span>P
        </div>
      </div>
      <div class="graph graph--border flex-grow">
        <h4 class="text-center">Afschrijvingen</h4>
        <Loading v-if="!afschr_data" />
        <LineChart v-if="afschr_data.length" :table_data="afschr_data" />
        <div class="flex _row center">
          <span class="dot dot--s m1"></span>S<span class="dot dot--v m1"></span
          >V
        </div>
      </div>
      <div class="graph graph--border flex-grow">
        <h4 class="text-center">Stadagen</h4>
        <Loading v-if="!stadagen_data" />
        <LineChart v-if="stadagen_data.length" :table_data="stadagen_data" />
        <div class="flex _row center">
          <span class="dot dot--s m1"></span>S<span class="dot dot--v m1"></span
          >V<span class="dot dot--p m1"></span>P
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import LineChart from "@/components/LineChart.vue";
import Loading from "@/components/Loading.vue";

export default {
  props: ["bu"],
  components: { LineChart, Loading },
  data: () => ({
    stock_history_year: null,
    aantal_data: [],
    waarde_data: [],
    afschr_data: [],
    stadagen_data: [],
    loading: true,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu){
      this.loading = true;
      this.aantal_data = [];
      this.waarde_data = [];
      this.afschr_data = [];
      this.stadagen_data = [];
      this.stock_history_year = null;
      this.getData(newbu);
    }
  },
  methods: {
    getData(bu) {
      request(`stock-history-year/${bu}`, "GET").then(
        ({ stock_history_year }) => {
          this.stock_history_year = stock_history_year;
          this.mutateData(stock_history_year);
        }
      );
    },

    mutateData(stock_history_year) {
      Object.keys(stock_history_year).forEach((i) => {
        const week = i.substring(4);
        const P = stock_history_year[i][0];
        const S = stock_history_year[i][1];
        const V = stock_history_year[i][2];

        const P_aantal = P.aantal;
        const S_aantal = S.aantal;
        const V_aantal = V.aantal;

        const P_waarde = P.voorraadwaarde;
        const S_waarde = S.voorraadwaarde;
        const V_waarde = V.voorraadwaarde;

        const S_afschr = S.afschrijving;
        const V_afschr = V.afschrijving;

        const P_stadagen = P.gemdagen;
        const S_stadagen = S.gemdagen;
        const V_stadagen = V.gemdagen;
        this.aantal_data.push({
          week,
          title: "Aantal",
          p: P_aantal,
          s: S_aantal,
          v: V_aantal,
        });
        this.waarde_data.push({
          week,
          title: "Waarde",
          p: P_waarde,
          s: S_waarde,
          v: V_waarde,
        });
        this.afschr_data.push({
          week,
          title: "Afschrijving",
          s: S_afschr,
          v: V_afschr,
        });
        this.stadagen_data.push({
          week,
          title: "Stadagen",
          p: P_stadagen,
          s: S_stadagen,
          v: V_stadagen,
        });
      });
      this.loading = false;
    },
  },
};
</script>
